import { useEffect, useState } from "react";
import { ScrollView, View, Text, ActivityIndicator } from '../components/ui'
import { useDripsyTheme } from "dripsy";
import { useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { TrainingScreenProps } from "../navigation/types";
import { EventHeader } from "../components/ui/EventHeader";
import { Training } from "../models/TrainingData";
import { AntDesign } from '@expo/vector-icons';
import { useMember } from "../hooks/useMember";
import TrainingProgramView from "../components/training/TrainingProgramView";
import { useQuery } from "@tanstack/react-query";
import { useMemberApi } from "../api/useMemberApi";
import { useConfig } from "../config/useConfig";

export default function TrainingProgramScreen({ route }: TrainingScreenProps<'TrainingProgram'>) {
    const { member, showHeader, setHeader } = useMember();
    const { getTrainingData } = useMemberApi()
    const { isPending, isError, data, error, refetch } = useQuery({ queryKey: ['trainingData', member?.email], queryFn: () => getTrainingData(), staleTime: Infinity })
    const [training, setTraining] = useState<Training>();
    const { theme } = useDripsyTheme()
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const reggieId = Number(route.params.id)
    const { appTheme } = useConfig()

    const trainingData = data

    useEffect(() => {
        if (trainingData) {
            const allTraining = [...trainingData.active, ...trainingData.future, ...trainingData.past]
            for (const i in allTraining) {
                let tempTraining = allTraining[i]
                if (tempTraining.reggieId === reggieId) {
                    setTraining(tempTraining)
                    break;
                }
            }
        }
    }, [trainingData])

    useEffect(() => {
        setHeader(false);
    }, [showHeader])

    const viewTraining = (tr: Training) => {
        navigation.push('TrainingDetails', { id: tr.reggieId });
    }

    function goBack() {
        setHeader(true);
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('TrainingView')
    }

    return (
        <View sx={{ backgroundColor: '#fff', height: '100%' }}>
            {isPending ? (<ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />) : (
                <ScrollView sx={{ flex: 1 }}>
                    {training && <EventHeader training={training} />}
                    <View sx={{ marginTop: '$3', marginLeft: '$3', ...theme.layout.viewBack }}>
                        <AntDesign sx={{ cursor: 'pointer' }} name={"left"} size={28} color={theme.colors.$primary} />
                        <Text sx={{ marginTop: 7, cursor: 'pointer', color: appTheme ? appTheme.primaryColor : '#000', fontWeight: 600 }} onPress={() => { goBack() }}>Back</Text>
                    </View>
                    <View sx={{ paddingTop: 50, ...theme.layout.view }}>
                        {training && <TrainingProgramView training={training} onPress={viewTraining} />}
                    </View>
                </ScrollView>
            )}
        </View>
    )
}