import { SectionList } from "react-native";
import { Training, TrainingData } from "../models/TrainingData";
import { TrainingMgmtData } from "../models/TrainingMgmtData";
import { H2, H3, Text, View } from './ui';
import { Pressable } from "./ui/Pressable"
import { useEffect, useState, useRef } from "react";
import { useDripsyTheme } from "dripsy";
import { useMember } from "../hooks/useMember";
import { StyleSheet } from 'react-native';
import { TrainingInstructorData } from "../models/TrainingInstructorData";
import { useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import CalendarListSelector from '../components/ui/CalendarListSelector';
import { TrainingCard } from "./training/TrainingCard";
import SectionLinks from "./ui/ScrollToSection";
import { Section } from "../models/Section";
import NoticeIcon from "svg/icon-notice.svg";
import { QueryObserverResult } from "@tanstack/react-query";

const ACTIVE = 1
const PAST = 2
const FUTURE = 3

interface TrainingStates {
    id: string | number,
    open: boolean
}

interface TrainingCardViewProps {
    trainingData: TrainingData | TrainingMgmtData | TrainingInstructorData
    loading: boolean
    onRefreshTraining?: () => Promise<QueryObserverResult<TrainingData, Error>>
    setupValidateEmailModal?: () => void
    windowSelection?: (selection: string) => void
    FooterContent: React.ElementType
}

export default function TrainingCardView(props: TrainingCardViewProps) {
    const { member } = useMember()
    const { trainingData, loading, onRefreshTraining, setupValidateEmailModal, windowSelection, FooterContent } = props
    const [trainingStates, setTrainingStates] = useState<TrainingStates[]>([])
    const { theme } = useDripsyTheme()
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const mainRef = useRef<SectionList<Section>>(null)
    const DATA: Section[] = [
        {
            title: "Your Current Enrollments",
            view: ACTIVE,
            data: trainingData ? trainingData.active : []
        },
        {
            view: FUTURE,
            data: trainingData ? trainingData.future : []
        },
        {
            title: "Past Training",
            view: PAST,
            data: trainingData ? trainingData.past : []
        }
    ]

    useEffect(() => {
        if (trainingData) {
            const allTraining = [...trainingData.active, ...trainingData.future, ...trainingData.past]
            let states: TrainingStates[] = [];
            allTraining.map((t, i) => {
                states.push({ id: t.id, open: t.open })
            })
            setTrainingStates(states)
        }
    }, [trainingData])

    const viewTraining = (training: Training) => {
        if (training.program) {
            navigation.navigate('TrainingProgram', { id: training.reggieId });
        } else {
            if (training.reggieId > 0) {
                navigation.navigate('TrainingDetails', { id: training.reggieId });
            } else if (training.resellId > 0) {
                navigation.navigate('TrainingDetails', { id: `r${training.resellId}` });
            }
        }
    }

    const ValidateEmailMessage = () => {
        if (member && !member.emailValidated && setupValidateEmailModal !== undefined) {
            return <Pressable onPress={setupValidateEmailModal} sx={{ marginTop: 11, flexDirection: "row", justifyContent: "center" }}>
                <Text sx={{ bg: 'black', color: 'white', fontWeight: 500, padding: '$3' }}>
                    <NoticeIcon fill='white' style={{ width: 17, marginRight: 7, marginTop: -3, position: 'relative', top: 4 }} />
                    Click here to validate your email for access to past classes and personal information.
                </Text>
            </Pressable>
        }
        return null;
    }

    const Header = ({ title, view, data }: { title?: string | undefined, view: number, data: any }) => {
        return (title &&
            <View>
                <H2 sx={theme.styles.smallheader}>{title}</H2>
                {data.length === 0 &&
                    <View sx={{ flexDirection: 'column', alignItems: 'center' }}>
                        {(setupValidateEmailModal !== undefined && view === PAST && member && !member.emailValidated) ?
                            <Text sx={{ color: '$primary', fontWeight: 500, textDecorationLine: 'underline' }} onPress={setupValidateEmailModal}>
                                <NoticeIcon fill={theme.colors.$primary} style={{ width: 40, marginRight: 9, position: 'relative', top: 15 }} />
                                Click here to validate your email for access to past classes.
                            </Text>
                            : (trainingData.active.length === 0 && trainingData.future.length === 0) && <Text sx={{ padding: 20, fontStyle: 'italic', color: '#a6a6a7' }}>No Active Enrollments</Text>
                        }
                    </View>
                }
            </View>
        )
    }

    const styles = StyleSheet.create({
        triangle: {
            width: 0,
            height: 0,
            backgroundColor: "transparent",
            borderStyle: "solid",
            borderLeftWidth: 16,
            borderRightWidth: 16,
            borderBottomWidth: 20,
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
            borderBottomColor: "white",
            transform: 'rotate(35deg)',
            position: "absolute",
            right: -10,
            top: -13
        }
    });

    const Item = ({ training, section }: { training: Training, section: any }) => {
        return (<>
            <Pressable onPress={() => viewTraining(training)} key={training.id} sx={theme.card}>
                {training.program ? (
                    <Text sx={{ ...theme.text.trainingCardLabel, backgroundColor: '$secondary', color: '#fff' }}>
                        Program
                        <View style={[styles.triangle]} />
                    </Text>
                ) : (
                    <Text sx={{ ...theme.text.trainingCardLabel, backgroundColor: '$highlight', color: '$highlightText'}}>
                        Class
                        <View style={[styles.triangle]} />
                    </Text>
                )}
                <TrainingCard training={training} />
            </Pressable>
        </>)
    }
    var showCalendarOption = ((member && member.emailValidated) ? true : false) && (!(trainingData && trainingData.active.length == 0 && trainingData.future.length == 0 && trainingData.past.length == 0) ? true : false);
    return (
        <>
            <SectionList
                ref={mainRef}
                contentContainerStyle={theme.layout.view}
                sections={DATA}
                stickySectionHeadersEnabled={false}
                keyExtractor={(item, index) => item.id}
                renderItem={({ item, section }) => <Item training={item} section={section} />}
                renderSectionHeader={({ section: { title, view, data } }) => <Header title={title} data={data} view={view} />}
                renderSectionFooter={({ section: { title } }) => title && <View sx={{ height: 85 }}><View sx={{ borderBottomColor: '$gray', borderBottomWidth: 1, position: 'relative', top: '72%' }}></View></View>}
                refreshing={loading}
                onRefresh={onRefreshTraining}
                ListEmptyComponent={<H3>No Training Found</H3>}
                ListHeaderComponent={
                    <>
                        {showCalendarOption && windowSelection && <CalendarListSelector initalState="TrainingView" pageState={windowSelection} />}
                        {showCalendarOption && <SectionLinks mainRef={mainRef} DATA={DATA} />}
                        {(member && !member.emailValidated) && <ValidateEmailMessage />}
                    </>
                }
                ListFooterComponent={<FooterContent />}
            />
        </>
    )

}