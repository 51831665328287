import { Text, View } from '../ui';
import { AntDesign } from '@expo/vector-icons';
import { Training } from '../../models/TrainingData';
import { Event } from '../../models/Event';
import CalendarIcon from '../ui/CalendarIcon';
import { useDripsyTheme } from 'dripsy';

interface ProgramCardProps {
    training: Training | Event
}

export function ProgramCard(props: ProgramCardProps) {
    const { training } = props
    const { theme } = useDripsyTheme()

    return (
        <>
            <Text sx={{
                color: '$primary', maxHeight: '104px', fontSize: '18px', fontWeight: '900', marginTop: 10, marginLeft: 0, letterSpacing: '0.1px',
                height: 75, lineHeight: 25, overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3
            }}>
                {`${training.name}`}
            </Text>
            <Text sx={{ flexDirection: 'row', color: '$primary', alignItems: 'center', display: 'block', position: 'absolute', bottom: 43 }}>
                <CalendarIcon style={{ width: 23, marginRight: 9, position: 'relative', top: 6 }} />
                {training.customDate.length > 0 ? (
                    <Text sx={{ color: '$primary', fontSize: 13, top: -2 }}>Date: {training.customDate}</Text>
                ) : (
                    <Text sx={{ color: '$primary', fontSize: 13, top: -2 }}>Date: {training.startDate.toString()} - {training.endDate.toString()}</Text>
                )}
            </Text>
            <View sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <View sx={{ flexDirection: 'column', alignItems: 'right' }}>
                    <Text sx={{ color: '$primary', textAlign: 'right', fontWeight: 400, marginRight: 6, textDecorationLine: 'underline' }}>View Details</Text>
                </View>
                <AntDesign name={"right"} size={20} color={theme.colors.$primary} />
            </View>
        </>
    )
}