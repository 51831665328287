import { useNavigationContainerRef, LinkingOptions, NavigationContainer as NativeNavigationContainer } from '@react-navigation/native'
import * as Linking from 'expo-linking'
import { useRef } from 'react';
import Header from '../components/Header';
import { SafeAreaView } from '../components/ui'
import Logo from '../components/Logo';
import { Sidebar } from '../components/Sidebar';
import RootNavigator from './RootNavigator';
import PushNotificationsProvider from '../providers/PushNotificationsProvider';
import { Platform } from 'react-native';
import { TrackGoogleAnalyticsPage } from '../utils/GoogleAnalytics';
import { RootStackParamList } from './types';
import { useMember } from '../hooks/useMember';

export function NavigationContainer() {
    const { member } = useMember()
    const linking: LinkingOptions<RootStackParamList> = {
        prefixes: [Linking.createURL('/'), 'https://exitcertified.com', 'https://exitcertified4.stagingtank.com'],
        config: {
            initialRouteName: 'Tabs',
            screens: {
                Utility: {
                    initialRouteName: 'GetStarted',
                    screens: {
                        GetStarted: 'get-started/:action?/:id?',
                        Validate: 'validate'
                    }
                },
                Onboarding: {
                    initialRouteName: 'Login',
                    screens: {
                        Login: 'login',
                        PushNotification: 'pushnotification',
                        ForgotPassword: 'forgotpassword',
                        Signup: 'signup'
                    }
                },
                Tabs: {
                    initialRouteName: 'Training',
                    screens: {
                        Training: {
                            screens: {
                                TrainingView: 'training',
                                TrainingDetails: 'training/trainingdetails/:id?',
                                TrainingProgram: 'training/trainingprogram/:id?'
                            }
                        },
                        Quotes: 'quotes',
                        Profile: 'profile',
                        Notifications: 'notifications',
                        Subscriptions: 'subscriptions'
                    }
                }
            }
        }
    }
    const navigationRef: any = useNavigationContainerRef();
    const routeNameRef: any = useRef();

    return (
        <>
            {member && <Sidebar />}
            <SafeAreaView sx={{ paddingTop: Platform.OS === "android" ? 50 : 0 }}>
                <Logo />
                <Header />
            </SafeAreaView>
            <NativeNavigationContainer
                linking={linking}
                ref={navigationRef}
                onReady={() => { routeNameRef.current = navigationRef.getCurrentRoute().name; }}
                onStateChange={async () => {
                    const previousRouteName = routeNameRef.current;
                    const currentRouteName = navigationRef.getCurrentRoute().name;
                    const trackScreenView = (routeName: string) => {
                        TrackGoogleAnalyticsPage("pageview", '/' + routeName.toLowerCase(), routeName);
                    }
                    if (previousRouteName !== currentRouteName) {
                        routeNameRef.current = currentRouteName;
                        await trackScreenView(currentRouteName);
                    }
                }}
            >
                <PushNotificationsProvider>
                    <RootNavigator />
                </PushNotificationsProvider>

            </NativeNavigationContainer>
        </>
    )
}