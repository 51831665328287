import { Text, View } from './';
import { Section } from "../../models/Section";

export default function SectionLinks({ mainRef, DATA, staffHub }: { mainRef: any, DATA?: Section[], staffHub?: boolean }) {
    const ScrollToSection = (section: any) => {
        mainRef.current?.scrollToLocation({
            animated: true,
            itemIndex: 0,
            sectionIndex: section.view - 1,
            viewOffset: 0,
            viewPosition: 0
        });
    }
    return (DATA &&
        <View sx={{ margin: 'auto', marginTop: staffHub ? '$4' : 0, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            {DATA.map((section: any, index: number) => (section.title &&
                <View sx={{ display: 'inline-block' }} key={index}>
                    <Text sx={{ 
                            marginX: '$1', 
                            color: '$primary', 
                            textTransform: 'uppercase', 
                            fontSize: 13, 
                            fontWeight: 500, 
                            textDecorationLine: 'underline'
                        }}
                        onPress={() => { ScrollToSection(section) }}>
                        {section.title}
                    </Text>
                    {DATA.length - 1 > index && <Text sx={{ marginX: '$1' }}> </Text>}
                </View>
            ))}
        </View>
    );
}