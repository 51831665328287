
import CalendarIconBlue from "svg/calendar-icon-blu.svg";
import CalendarIconOrange from "svg/calendar-icon-orng.svg";
import { useConfig } from "../../config/useConfig";
import { StyleProp } from "react-native";

interface CalendarIconProps {
    style: StyleProp<any>
}

export default function CalendarIcon(props: CalendarIconProps) {
    const { brand } = useConfig()
    const { style } = props

    return (
        <>
            {['accelebrate','ascendient'].includes(brand) ? (
                <CalendarIconOrange style={style} />
            ):(
                <CalendarIconBlue style={style} />
            )}
        </>
    )

}