import { useRef, useState } from "react";
import { Text, TextInput, View } from "../../components/ui";
import { useMember } from "../../hooks/useMember";
import { Pressable } from "../ui/Pressable";
import { TextInput as NativeTextInput } from 'react-native'
import { useQueryClient } from "@tanstack/react-query";

type ValidateEmailProps = {
    onSuccess?: () => {}
    customMsg?: string
}

export default function ValidateEmail(props: ValidateEmailProps) {
    const { onSuccess, customMsg } = props
    const { member, getEmailCode, validateCode } = useMember()
    const [showCodeInput, setShowCodeInput] = useState(false)
    const [emailCode, setEmailCode] = useState("")
    const [codeError, setCodeError] = useState("")
    const codeInputRef = useRef<NativeTextInput>(null)

    const queryClient = useQueryClient()

    const validateEmail = async () => {
        try {
            await getEmailCode();
            setShowCodeInput(true)
        } catch (err: any) {
            setCodeError(err.toString())
        }
    }

    const validateEmailCode = async () => {
        try {
            await validateCode(emailCode)
            setCodeError("")
            setShowCodeInput(false)
            queryClient.invalidateQueries({ queryKey: ['trainingData'] })
            if (onSuccess) {
                onSuccess()
            }
        } catch (err: any) {
            setCodeError(err.toString())
        }
    }

    return (
        <View sx={{ justifyContent: 'center', alignItems: 'center', marginVertical: 20, marginX: '$4' }}>
            {codeError.length > 0 && <Text sx={{ color: 'white', bg: '$error', fontWeight: 'bold', padding: '$2', marginBottom: '$2', borderRadius: 5, minWidth: 400, textAlign: 'center' }}>{codeError}</Text>}
            {false && <Text sx={{ color: '$primary', bg: '$highlight', fontWeight: 'bold', padding: '$2', borderRadius: 5, minWidth: 400, textAlign: 'center' }}>Email Validated</Text>}
            {!member?.emailValidated && (
                <>
                    {customMsg && customMsg.length > 0 ? (
                        <Text>{customMsg}</Text>
                    ) : (
                        <Text>Validate your email to enable extra features like receipts, course completion certificates and past classes.</Text>
                    )}
                    {!showCodeInput &&
                        <>
                            <Text sx={{ fontWeight: 'bold', marginVertical: 6 }}>Clicking validate email will send you a validation code.</Text>
                            <Pressable onPress={validateEmail} version="buttons.primary">
                                <Text sx={{ color: 'white' }}>Validate Email</Text>
                            </Pressable>
                        </>
                    }

                    {showCodeInput &&
                        <>
                            <Text sx={{ fontWeight: 'bold', marginVertical: 6 }}>Check your email for the validation code</Text>
                            <TextInput
                                ref={codeInputRef}
                                autoCorrect={false}
                                onChangeText={(code) => setEmailCode(code)}
                                maxLength={5}
                                sx={{ bg: '$gray', padding: '$2', marginY: '$2', width: 200 }}
                                placeholder="Email Validation Code"
                                placeholderTextColor="gray" verticalAlign={undefined} id={undefined} aria-label={undefined} aria-busy={undefined} aria-checked={undefined} aria-disabled={undefined} aria-expanded={undefined} aria-selected={undefined} aria-labelledby={undefined} aria-valuemax={undefined} aria-valuemin={undefined} aria-valuenow={undefined} aria-valuetext={undefined} aria-hidden={undefined} aria-live={undefined} aria-modal={undefined} role={undefined} accessibilityLabelledBy={undefined} accessibilityLanguage={undefined} onPressIn={undefined} onPressOut={undefined} lineBreakStrategyIOS={undefined} onPointerEnter={undefined} onPointerEnterCapture={undefined} onPointerLeave={undefined} onPointerLeaveCapture={undefined} onPointerMove={undefined} onPointerMoveCapture={undefined} onPointerCancel={undefined} onPointerCancelCapture={undefined} onPointerDown={undefined} onPointerDownCapture={undefined} onPointerUp={undefined} onPointerUpCapture={undefined} autoComplete={undefined} enterKeyHint={undefined} inputMode={undefined} cursorColor={undefined}
                            />
                            <Pressable onPress={validateEmailCode} version="buttons.primary">
                                <Text sx={{ color: 'white' }}>Validate Code</Text>
                            </Pressable>
                        </>
                    }
                </>
            )}
        </View>
    )
}