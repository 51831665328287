import defaultAppFeatures from "./default-features.json";
import { useMemo } from "react";
import Constants from "expo-constants";
import { ImageSourcePropType, Platform } from "react-native";
import deepmerge from "deepmerge";

interface TabFeatures {
  Active?: boolean;
  RecommendedCourses?: boolean;
}

export interface AppTabs {
  Training?: TabFeatures;
  Quotes?: TabFeatures;
  Profile?: TabFeatures;
  Subscriptions?: TabFeatures;
  Notifications?: TabFeatures;
}

interface AppFeatures {
  Tabs: AppTabs;
}

interface AppTheme {
  siteLogo: ImageSourcePropType;
  brandLogo: ImageSourcePropType;
  heroBG: string;
  primaryColor: string;
  highlightColor: string;
  highlightTextColor: string;
}

const ECFeatures: AppFeatures = {
  Tabs: {
    Training: {
      RecommendedCourses: true,
    },
    Quotes: {
      Active: true,
    },
    Profile: {
      Active: true,
    },
    Subscriptions: {
      Active: true,
    },
    Notifications: {
      Active: true,
    },
  },
};

interface EnvVars {
  brand: string;
  apiBase: string;
  webBase: string;
  GoogleAnalyticsTrackingId?: string;
  features?: AppFeatures;
  appTheme?: AppTheme;
  qualifiedExperienceId?: string;
  env: string;
}

interface Environment {
  [key: string]: EnvVars;
}

let ENV: Environment = {
  "traininghub.stagingtank.com": {
    brand: "exitcertified",
    apiBase: "https://ascendient.stagingtank.com/hub/v1",
    webBase: "https://ascendient.stagingtank.com",
    features: ECFeatures,
    qualifiedExperienceId: "experience-1674238012087",
    GoogleAnalyticsTrackingId: "G-S6BM66J8C4",
    env: "staging",
  },
  "traininghub.exitcertified.com": {
    brand: "exitcertified",
    apiBase: "https://traininghub.exitcertified.com/hub/v1",
    webBase: "https://www.ascendientlearning.com",
    features: ECFeatures,
    qualifiedExperienceId: "experience-1674238012087",
    GoogleAnalyticsTrackingId: "G-JNTKTR95FC",
    env: "production",
  },
  //ACCELEBRATE START
  "traininghub.accelebrate.com": {
    brand: "accelebrate",
    apiBase: "https://traininghub.accelebrate.com/hub/v1",
    webBase: "https://www.ascendientlearning.com",
    qualifiedExperienceId: "experience-1701204247019",
    env: "production",
    appTheme: {
      siteLogo: require("../../assets/training-hub-logo-orange.png"),
      brandLogo: require("../../assets/brands/acc-axcel.png"),
      heroBG: require("../../assets/acc_hero-bg.jpg"),
      primaryColor: "#373f50",
      highlightColor: "#a45236",
      highlightTextColor: "#ffffff",
    },
  },
  "accelebrate-traininghub.stagingtank.com": {
    brand: "accelebrate",
    apiBase: "https://ascendient.stagingtank.com/hub/v1",
    webBase: "https://accelebrate-traininghub.stagingtank.com",
    qualifiedExperienceId: "experience-1701204247019",
    env: "staging",
    appTheme: {
      siteLogo: require("../../assets/training-hub-logo-orange.png"),
      brandLogo: require("../../assets/brands/acc-axcel.png"),
      heroBG: require("../../assets/acc_hero-bg.jpg"),
      primaryColor: "#373f50",
      highlightColor: "#a45236",
      highlightTextColor: "#ffffff",
    },
  },
  //ACCELEBRATE END

  //WEB AGE START
  "traininghub.webagesolutions.com": {
    brand: "webage",
    apiBase: "https://traininghub.webagesolutions.com/hub/v1",
    webBase: "https://www.ascendientlearning.com",
    qualifiedExperienceId: "experience-1701802361103",
    env: "production",
    appTheme: {
      siteLogo: require("../../assets/training-hub-logo.png"),
      brandLogo: require("../../assets/brands/webage.png"),
      heroBG: require("../../assets/hero-bg.jpg"),
      primaryColor: "#007cbf",
      highlightColor: "#262626",
      highlightTextColor: "#ffffff",
    },
  },
  "webage-traininghub.stagingtank.com": {
    brand: "webage",
    apiBase: "https://ascendient.stagingtank.com/hub/v1",
    webBase: "https://webage-traininghub.stagingtank.com",
    qualifiedExperienceId: "experience-1701802361103",
    env: "staging",
    appTheme: {
      siteLogo: require("../../assets/training-hub-logo.png"),
      brandLogo: require("../../assets/brands/webage.png"),
      heroBG: require("../../assets/hero-bg.jpg"),
      primaryColor: "#007cbf",
      highlightColor: "#262626",
      highlightTextColor: "#ffffff",
    },
  },
  //WEB AGE END

  //ASCENDIENT START
  "traininghub.ascendientlearning.com": {
    brand: "ascendient",
    apiBase: "https://traininghub.ascendientlearning.com/hub/v1",
    webBase: "https://www.ascendientlearning.com",
    qualifiedExperienceId: "experience-1701802361103",
    features: ECFeatures,
    env: "production",
    appTheme: {
      siteLogo: require("../../assets/asc-training-hub-logo.png"),
      brandLogo: require("../../assets/brands/ascendient.png"),
      heroBG: require("../../assets/asc-bg.jpg"),
      primaryColor: "#67162B",
      highlightColor: "#300E13",
      highlightTextColor: "#ffffff",
    },
  },
  "ascendient-traininghub.stagingtank.com": {
    brand: "ascendient",
    apiBase: "https://ascendient.stagingtank.com/hub/v1",
    webBase: "https://ascendient.stagingtank.com",
    qualifiedExperienceId: "experience-1701802361103",
    features: ECFeatures,
    env: "staging",
    appTheme: {
        siteLogo: require("../../assets/asc-training-hub-logo.png"),
        brandLogo: require("../../assets/brands/ascendient.png"),
        heroBG: require("../../assets/asc-bg.jpg"),
        primaryColor: "#67162B",
        highlightColor: "#300E13",
        highlightTextColor: "#ffffff",
    },
},
  //ASCENDIENT END

  "127.0.0.1": {
    // ASCENDIENT
    brand: "ascendient",
    apiBase: "http://127.0.0.1:8000/hub/v1",
    webBase: "http://127.0.0.1:8000",
    qualifiedExperienceId: "experience-1701802361103",
    features: ECFeatures,
    env: "development",
    appTheme: {
      siteLogo: require("../../assets/asc-training-hub-logo.png"),
      brandLogo: require("../../assets/brands/ascendient.png"),
      heroBG: require("../../assets/asc-bg.jpg"),
      primaryColor: "#67162B",
      highlightColor: "#300E13",
      highlightTextColor: "#ffffff",
    },

    // ACCELEBRATE
    // brand: "accelebrate",
    // apiBase: "http://127.0.0.1:8000/hub/v1",
    // webBase: "http://127.0.0.1:8000",
    // env: "development",
    // appTheme: {
    //     siteLogo: require("../../assets/training-hub-logo-orange.png"),
    //     brandLogo: require("../../assets/brands/acc-axcel.png"),
    //     heroBG: require("../../assets/acc_hero-bg.jpg"),
    //     primaryColor: "#373f50",
    //     highlightColor: "#a45236",
    //     highlightTextColor: "#ffffff"
    // }

    // WEB AGE
    // brand: "webage",
    // apiBase: "http://127.0.0.1:8000/hub/v1",
    // webBase: "http://127.0.0.1:8000",
    // env: "development",
    // appTheme: {
    //     siteLogo: require("../../assets/training-hub-logo.png"),
    //     brandLogo: require("../../assets/brands/webage.png"),
    //     heroBG: require("../../assets/hero-bg.jpg"),
    //     primaryColor: "#007cbf",
    //     highlightColor: "#262626",
    //     highlightTextColor: "#ffffff"
    // }
  },
  default: {
    brand: "ascendient",
        apiBase: "http://localhost:8000/hub/v1",
        webBase: "http://localhost:8000",
        env: "development",
        features: ECFeatures,
        appTheme: {
            siteLogo: require("../../assets/asc-training-hub-logo.png"),
            brandLogo: require("../../assets/brands/ascendient.png"),
            heroBG: require("../../assets/asc-bg.jpg"),
            primaryColor: "#67162B",
            highlightColor: "#300E13",
            highlightTextColor: "#ffffff"
        }
  },
};


export function getEnvVars() {
  let env = "default";
  let reggieID = null;

  // Get From URL (if avaliable)
  if (Platform.OS == "web") {
    env = window.location.hostname;

    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      reggieID = params.get("reggieID");
    }
  }

  // Override with ENV variable setting found in app.config
  if (
    Constants.expoConfig &&
    Constants.expoConfig.extra &&
    Constants.expoConfig.extra.ENVIRONMENT
  ) {
    env = Constants.expoConfig.extra.ENVIRONMENT;
  }

  var ret = ENV[env] ? ENV[env] : ENV.default;

  ret.features =
    ret.features && !reggieID
      ? deepmerge(defaultAppFeatures, ret.features)
      : defaultAppFeatures;

  // Override API Base if set
  if (
    Constants.expoConfig &&
    Constants.expoConfig.extra &&
    Constants.expoConfig.extra.API_BASE
  ) {
    ret.apiBase = Constants.expoConfig.extra.API_BASE;
  }

  return ret;
}

export function useConfig() {
  const config = useMemo(getEnvVars, [getEnvVars]);

  return config;
}
