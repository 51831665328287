import { View, Text } from "../../components/ui"
import { Pressable } from "./Pressable"
import { useConfig } from "../../config/useConfig";
import { useState } from 'react';
import ListItemBlue from "svg/list-icon-blu.svg";
import ListItemOrange from "svg/list-icon-orng.svg";
import CalendarIcon from "./CalendarIcon";

interface CalendarListProps {
    initalState: string
    pageState: (state: string) => void
}

export default function CalendarListSelector(props: CalendarListProps) {
    const { pageState, initalState } = props
    const { brand } = useConfig();
    const [trainingView, setTrainingView] = useState<string>(initalState ? initalState : 'TrainingView');
    const setState = (state: string) => {
        setTrainingView(state);
        pageState(state);
    }
    return (
        <View sx={{ display: 'flex', flexDirection: 'row', alignItems: 'left', height: 60, marginLeft: 15, marginBottom: 8 }}>
            <Pressable sx={{ flexDirection: 'row', alignItems: 'center', marginRight: 15 }} onPress={() => { setState('TrainingView') }}>
                {['accelebrate','ascendient'].includes(brand) ? (
                    <ListItemOrange style={{ width: 23, marginRight: 10, marginLeft: 2, position: 'relative', top: 6 }} />
                ):(
                    <ListItemBlue style={{ width: 23, marginRight: 10, marginLeft: 2, position: 'relative', top: 6 }} />
                )}

                <Text sx={{ marginTop: 10, color: '$primary', fontWeight: 'bold', marginLeft: '$2', textDecorationLine: trainingView == 'TrainingView' ? 'underline' : null }}>List View</Text>
            </Pressable>
            <Pressable sx={{ flexDirection: 'row', alignItems: 'center' }} onPress={() => { setState('TrainingCalendar') }}>
                <CalendarIcon style={{ width: 23, marginRight: 10, marginLeft: 2, position: 'relative', top: 6 }} />
                <Text sx={{ marginTop: 10, color: '$primary', fontWeight: 'bold', marginLeft: '$2', textDecorationLine: trainingView == 'TrainingCalendar' ? 'underline' : null }}>Calendar View</Text>
            </Pressable>
        </View>
    )
}