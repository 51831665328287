import camelcaseKeys from 'camelcase-keys'
import Constants from "expo-constants"
import { useContext } from 'react';
import { Platform } from 'react-native';
import { useConfig } from "../config/useConfig";
import { MemberDataContext } from '../providers/MemberDataProvider';
import { isDev } from '../utils/EnvironmentUtils';
import Storage from "../utils/Storage";
import { useMember } from '../hooks/useMember';
import { MemberContext } from '../providers/MemberProvider';

interface RestOptions {
    camelCaseKeys: boolean
    jsonBody: boolean,
    download?: boolean
}

const defaultRestOptions: RestOptions = {
    camelCaseKeys: true,
    jsonBody: true,
    download: false
}

const multipartRestOptions: RestOptions = {
    camelCaseKeys: true,
    jsonBody: false,
    download: false
}

export function useRestUtils() {
    const { apiBase } = useConfig()
    const { updateMemberData } = useContext(MemberDataContext)
    const { member, updateMember } = useContext(MemberContext)

    function apiKeyHeader(): { 'X-APIKey': string, 'X-BundleVersion'?: string } | undefined {
        let apiKey = getApiKey()

        if (apiKey) {
            if (isDev()) {
                console.log("Using API Key", apiKey)
            }

            const deviceHeaders = { 'X-BundleVersion': Constants.expoConfig?.version }

            return Platform.OS !== 'web' ? { 'X-APIKey': apiKey, ...deviceHeaders } : { 'X-APIKey': apiKey }
        }

        return undefined
    }

    function headers(options: RestOptions) {
        let headers = options.jsonBody ? { "content-type": "application/json", ...apiKeyHeader() } : apiKeyHeader()

        return headers
    }

    async function get<T = any>(url: string, options: RestOptions = defaultRestOptions): Promise<T> {
        const request: RequestInit = {
            method: "GET",
            headers: headers(options)
        }

        console.log("GET", url)
        return execute(url, request, options)
    }

    async function post<T = any>(url: string, data: any = null, options: RestOptions = defaultRestOptions): Promise<T> {
        const request: RequestInit = {
            method: "POST",
            body: options.jsonBody ? JSON.stringify(data) : data,
            headers: headers(options)
        }

        console.log("POST", url)
        return execute(url, request, options)
    }

    async function patch<T = any>(url: string, data: any = null, options: RestOptions = defaultRestOptions): Promise<T> {
        const request: RequestInit = {
            method: "PATCH",
            body: options.jsonBody ? JSON.stringify(data) : data,
            headers: headers(options)
        }

        console.log("PATCH", url)
        return execute(url, request, options)
    }

    async function _delete<T = any>(url: string, options: RestOptions = defaultRestOptions): Promise<T> {
        const request: RequestInit = {
            method: "DELETE",
            headers: headers(options)
        }

        console.log("DELETE", url)
        return execute(url, request, options)
    }

    async function execute(url: string, request: RequestInit, options: RestOptions): Promise<any> {
        const apiUrl = `${apiBase}${url}`
        const response = await fetch(apiUrl, request)
        return await processResponse(response, options)
    }

    async function processResponse(response: Response, options: RestOptions): Promise<any> {
        console.log('RESPONSE', response.status)
        if (response.ok) {
            if (response.status >= 200 && response.status <= 299) {
                setApiKey(response.headers.get('X-APIKey'))
                updateMemberData(response.headers.get('X-UserData'))
            }
            if (response.status === 204) {
                return
            }
        } else {
            if (response.status >= 400 && response.status <= 499) {
                setApiKey(response.headers.get('X-APIKey'))
                updateMemberData(response.headers.get('X-UserData'))
            }
            if (response.status === 422) {
                const errorJson: any = camelcaseKeys(await response.json(), { deep: true })
                console.log("Error JSON payload:", errorJson)
                if (errorJson.errorCode === 'NOTAUTHENTICATED') {
                    deAuthMember()
                }
                throw new RestError(response.status, errorJson.errorCode, errorJson.message)
            } else {
                throw new RestError(response.status, "", response.statusText)
            }
        }
        if (options.download) {
            return response;
        } else {
            return options.camelCaseKeys ? camelcaseKeys(await response.json(), { deep: true }) : response.json()
        }
    }

    function deAuthMember(): void {
        if (member) {
            member.token = undefined
            member.emailValidated = false
            updateMember(member)
        }
    }

    return {
        multipartRestOptions,
        get,
        post,
        patch,
        headers,
        _delete
    }
}

export function getApiKey(): string | null {
    return Storage.get('apiKey')
}

export function setApiKey(key: string | null) {
    if (key) {
        Storage.set('apiKey', key)
    } else {
        Storage.remove('apiKey')
    }
}

export class RestError extends Error {
    errorCode: string
    status: number

    constructor(status: number, errorCode: string, message: string) {
        super(message)
        this.status = status
        this.errorCode = errorCode
        this.name = ''
    }
}