import { createNativeStackNavigator } from "@react-navigation/native-stack"
import GetStartedScreen from "../screens/GetStartedScreen"
import { UtilityParamList } from "./types"
import ValidateScreen from "../screens/ValidateScreen"

const UtilityStack = createNativeStackNavigator<UtilityParamList>()
export default function UtilityNavigator() {

    return (
        <UtilityStack.Navigator screenOptions={{ headerShown: false }}>
            <UtilityStack.Screen name="GetStarted" component={GetStartedScreen} />
            <UtilityStack.Screen name="Validate" component={ValidateScreen} />
        </UtilityStack.Navigator>
    )
}