import { useEffect, useState } from "react";
import { ActivityIndicator, Container, Text, View, SafeAreaView } from "../components/ui";
import { Pressable } from "../components/ui/Pressable";
import { UtilityScreenProps } from "../navigation/types";
import { useMember } from "../hooks/useMember";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RestError } from "../api/useRestUtils";
import FormTextInput from "../components/ui/forms/FormTextInput";
import { FormProvider, SubmitHandler, useForm, SubmitErrorHandler } from "react-hook-form";

interface FormValues {
    code: string
}

export default function ValidateScreen({}: UtilityScreenProps<'Validate'>) {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { member, postValidationRequired, fetchMember } = useMember()
    const [keyValue, setKeyValue] = useState("")
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const [showValidation, setShowValidation] = useState(false)
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const canSubmit = methods.formState.isValid && !methods.formState.isSubmitting
    
    useEffect(() => {
        async function loadAsync() {
            if (window.location.search) {
                const params = new URLSearchParams(window.location.search)
                const key = params.get('key')
                params.delete('key')

                if (key && key.length > 0) {
                    setKeyValue(key)
                    try {
                        await postValidationRequired(key)
                        await fetchMember()
                    } catch (err: any) {
                        checkError(err)
                    } finally {
                        setLoading(false)
                    }
                }
            }
        }

        loadAsync()
    }, [])

    useEffect(() => {
        if (member) {
            navigation.navigate("Tabs")
        }
    }, [member])

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const { code } = data

        if (canSubmit) {
            try {
                await postValidationRequired(keyValue, code);
                await fetchMember()
            } catch (err: any) {
                checkError(err)
            }
        }
    }
    
    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        return console.error("Form error(s):", errors)
    }

    function checkError(error: RestError | any) {
        if (error instanceof RestError) {
            if (error.errorCode === 'CODEVALIDATIONREQURED') {
                setShowValidation(true)
            } else if (error.errorCode === 'ERROR') {
                redirect()
            } else {
                setError(error.message)
            }
        } else {
            setError(error.toString())
        }
    }

    function redirect() {
        if (member) {
            navigation.navigate("Tabs")
        } else {
            navigation.navigate("Onboarding")
        }
    }

    if (loading) {
        return <ActivityIndicator size="large" color="$primary" sx={{padding: 20}} />
    }

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
            <Container variant="form" sx={{ marginY: '$4' }}>
                {error.length > 0 && (
                    <Text sx={{ color: '$error', fontWeight: 'bold', padding: '$2', borderRadius: 5, textAlign: 'center' }}>
                        Sorry, there was an issue. {error}
                    </Text>
                )}

                {showValidation &&
                    <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Text sx={{ fontWeight: 'bold', marginVertical: 6 }}>Check your email for the validation code</Text>
                        <FormProvider {...methods}>
                            <FormTextInput
                                name="code"
                                autoCorrect={false}
                                maxLength={6}
                                placeholder="Email Validation Code"
                                placeholderTextColor="gray"
                                rules={{
                                    required: "Code Required"
                                }}
                            />
                        </FormProvider>
                        
                        <Pressable onPress={methods.handleSubmit(onSubmit, onError)} version="buttons.primary" >
                            <Text sx={{ color: 'white' }}>Validate Code</Text>
                        </Pressable>
                    </SafeAreaView>
                }
            </Container>
        </View>
    )
}