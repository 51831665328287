import { ActivityIndicator, SafeAreaView, Text, View } from "../../components/ui";
import { useState } from "react";
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import alert from '../../utils/Common'
import { useMember } from "../../hooks/useMember";
import Member from "../../models/Member";
import FormTextInput from "../ui/forms/FormTextInput";
import { Pressable } from "../ui/Pressable";
import { useNavigation } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";

interface FormValues {
    email: string
    password: string
}

interface LoginFormProps {
    onLogin: (member: Member) => void
}

export default function LoginForm(props: LoginFormProps) {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { login, logout, getSsoLookup } = useMember()
    const [ showPassword, setShowPassword ] = useState(false)
    const [ loadingSso, setLoadingSso ] = useState(false)
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const canSubmit = methods.formState.isValid && !methods.formState.isSubmitting

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const { email, password } = data
        // await logout()
        try {
            if (showPassword) {
                const member = await login(email, password)
                props.onLogin(member)
            } else {
                setLoadingSso(true)
                const ssoDetails = await getSsoLookup(email)
                if (ssoDetails.loginType === 'OAuth') {
                    if (ssoDetails.authUrl) {
                        window.location.replace(ssoDetails.authUrl)
                    } else {
                        setLoadingSso(false)
                        alert("There was an issue", "Please try logging in again.")
                    }
                } else {
                    setLoadingSso(false)
                    setShowPassword(true)
                }
            }
        } catch (err: any) {
            alert("Sign In Error", err.toString()+'.');
        }
    }

    
    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        return console.error("Form error(s):", errors)
    }
    
    function onForgotPassword() {
        navigation.push("ForgotPassword")
    }

    if (loadingSso) {   
        return (
            <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <ActivityIndicator size="large" color="$primary" sx={{padding: 20}} />
            </SafeAreaView>
        )
    }

    return (
        <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center', paddingX: '$4' }}>
            <FormProvider {...methods}>
                <FormTextInput
                    name="email"
                    label="Email Address"
                    defaultValue=""
                    autoCompleteType="email"
                    textContentType="emailAddress"
                    rules={{
                        required: "Email Required"
                    }}
                    keyboardType="email-address"
                    autoCapitalize="none" 
                    onSubmitEditing={methods.handleSubmit(onSubmit, onError)} 
                />

                { showPassword && <FormTextInput
                    name="password"
                    label="Password"
                    defaultValue=""
                    autoCompleteType="password"
                    textContentType="password"
                    secureTextEntry={true}
                    autoFocus={true}
                    rules={{
                        required: "Password Required"
                    }}
                />}
                <View sx={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Pressable 
                        onPress={methods.handleSubmit(onSubmit, onError)}
                        version="buttons.primary"
                    >
                        <Text sx={{ color: 'white' }}>{showPassword ? "Log In" : "Continue"}</Text>
                    </Pressable>
                    
                    <Pressable
                        onPress={onForgotPassword}
                        version="buttons.gray">
                        <Text sx={{ color: 'white' }}>Forgot Password</Text>
                    </Pressable>
                </View>
            </FormProvider>
        </SafeAreaView>
    )
}