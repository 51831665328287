/**
 * Generate ICalLink (ics file export) for events and event sessions.
 * Based on @react-icalendar-link
 * https://www.npmjs.com/package/react-icalendar-link
 * 
 * Authors: Liam Hogan, joseph.chiang
 */

import * as React from "react";
import { SupportEmail, SupportPhone, downloadFile, isIOSSafari, isIOSChrome } from "../utils/Common";
import { Training, TrainingSession } from "../models/TrainingData";
import { v4 as uuidv4 } from 'uuid'

interface Props {
  className: string;
  style: Object;
  href: string;
  env: Object;
  training: Training;
  programs: Training[];
  name: string;
  brand: string;
  rawContent: string;
  isCrappyIE: boolean;
  isSupported: () => boolean;
  children: React.ReactNode
}

export interface ICalEvent {
  title: string;
  startTime: Date;
  description?: string;
  endTime?: Date;
  timezone?: string;
  location?: string;
  recurrence?: string;
  recurrenceCount?: number,
  image?: string;
  imageName?: string;
  categories?: string;
  attendees?: string[];
  url?: string;
}

function getCalendarEvents(training: Training, env: any, programs: Training[]): ICalEvent[] {
  var events: ICalEvent[] = [];
  var email = SupportEmail(env.brand);
  var phone = SupportPhone(env.brand);
  var url = window.location.origin + "/training";

  const createEvents = (loop_events: Training[], has_programs?: boolean) => {
    loop_events.forEach((ev: any) => {
      if (ev.sessions && ev.sessions.length > 0) {
        ev.sessions.forEach((sess: any) => {
          makeSessionEvent(sess, has_programs ? ev.name : null, has_programs ? ev.location : null, ev.timezoneLocation);
        })
      } else {
        makeEvent(ev);
      }
    });
  }

  const getDescription = () => {
    var description = [];

    if (training.virtualFormat === 'iMVP' || training.imvp) {
      description.push("To join your class, login to " + url + " using the email address that you are registered with. Click \"Join Your Live Virtual Class\".");
    } else {
      description.push("To access your class resources, login to " + url + " using the email address that you are registered with.");
    }

    description.push("If you need help or have any questions, you can reach the Training Support Team at " + phone + ", or email at " + email);
    description.push("We look forward to seeing you in class!");
    description.push("The Training Support Team");

    return description;
  }

  const makeEvent = (ev: Training) => {
    var start_date = new Date(ev.startDate + ' ' + ev.startTime + ' ' + ev.timezone);
    var end_date = new Date(ev.endDate + ' ' + ev.endTime + ' ' + ev.timezone);

    const diffInMs = end_date.getTime() - start_date.getTime();
    const diffInDays = Math.floor(diffInMs / 86400000);

    //Set the end date to the start date if multiple days defined by recurrenceCount
    end_date.setDate(start_date.getDate());
    end_date.setMonth(start_date.getMonth());
    end_date.setFullYear(start_date.getFullYear());

    var description = getDescription();
    var event = {
      title: "Training Class: " + ev.name,
      description: description.join(" \\n\\n"),
      startTime: start_date,
      endTime: end_date,
      categories: 'Education',
      recurrence: 'DAILY',
      recurrenceCount: diffInDays + 1,
      url: url,
      location: training.location,
      timezone: ev.timezoneLocation
    };
    events.push(event);
  }

  const makeSessionEvent = (ev: TrainingSession, name?: string, location?: string, timezoneLocation?: string) => {
    var description = getDescription();
    var start_date = new Date(ev.startDate + ' ' + ev.startTime + ' ' + ev.timezone);
    var end_date = new Date(ev.endDate + ' ' + ev.endTime + ' ' + ev.timezone);
    var event: ICalEvent;

    if (start_date.toDateString() === end_date.toDateString()) {
      event = {
        title: "Training Class: " + (name ? name : training.name),
        description: description.join(" \\n\\n"),
        startTime: start_date,
        endTime: end_date,
        categories: 'Education',
        url: url,
        timezone: timezoneLocation,
        location: location ? location : training.location
      };
    } else {
      const diffInMs = end_date.getTime() - start_date.getTime();
      const diffInDays = Math.floor(diffInMs / 86400000);
      
      end_date.setDate(start_date.getDate());
      end_date.setMonth(start_date.getMonth());
      end_date.setFullYear(start_date.getFullYear());

      event = {
        title: "Training Class: " + (name ? name : training.name),
        description: description.join(" \\n\\n"),
        startTime: start_date,
        endTime: end_date,
        categories: 'Education',
        recurrence: 'DAILY',
        recurrenceCount: diffInDays + 1,
        url: url,
        timezone: timezoneLocation,
        location: location ? location : training.location
      };
    }
    events.push(event);
  }

  if (programs) {
    var filteredPrograms = programs.filter((opp) => opp.reggieId != training.reggieId);
    createEvents(filteredPrograms, true);
  } else {
    createEvents([training]);
  }

  return events;
}

export default class ICalLink extends React.Component<Props> {
  // isCrappyIE: boolean;
  // FIXME - iOS Chrome doesn't support adding to iCal at the moment.
  // https://bugs.chromium.org/p/chromium/issues/detail?id=666211
  public static isSupported = () => !isIOSChrome();
  public static defaultProps: Partial<Props> = {
    name: "",
    href: "#add-to-calendar",
    rawContent: ""
  };
  constructor(props: any) {
    super(props);

    // this.isCrappyIE = !!(
    //   typeof window !== "undefined" &&
    //   window.navigator.msSaveOrOpenBlob &&
    //   window.Blob
    // );
  }
  handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    e.stopPropagation();

    const { training, name, programs, rawContent, env, brand } = this.props;
    const filename = name ? name : 'Class Invite' + (training.name ? ' for ' + training.name : '');
    const url: string = buildUrl(getCalendarEvents(training, env, programs), isIOSSafari(), rawContent, brand);
    // console.log(url);
    const blob: Blob = new Blob([url], {
      type: "text/calendar;charset=utf-8"
    });

    // IE
    // if (this.isCrappyIE) {
    //   window.navigator.msSaveOrOpenBlob(blob, filename);
    //   return;
    // }

    // Safari
    if (isIOSSafari()) {
      window.open(url, "_blank");
      return;
    }
    // Desktop
    downloadFile(undefined, blob, filename)
  };

  render() {
    const { children, href, className, style } = this.props;

    return (
      <a onClick={this.handleClick} {...{ href, className, style }}>
        {children}
      </a>
    );
  }
}


function pad(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }
  return `${num}`;
}

export function formatDate(dateTime: Date): string {
  // const dateTime = new Date(dateString);
  return [
    dateTime.getUTCFullYear(),
    pad(dateTime.getUTCMonth() + 1),
    pad(dateTime.getUTCDate()),
    "T",
    pad(dateTime.getUTCHours()),
    pad(dateTime.getUTCMinutes()) + "00Z"
  ].join("");
}

export function buildUrl(
  events: ICalEvent[],
  useDataURL: boolean = false,
  rawContent: string = "",
  brand: string = "Ascendient"
): string {
  const body: string[] = [];
  events.forEach(event => {

    if (!event || !event.startTime || !event.title) {
      throw Error("Both startTime and title fields are mandatory");
    }
    var now = new Date();

    body.push('BEGIN:VEVENT');
    // body.push(`DTSTART;TZID=${events[0].timezone}:${formatDate(event.startTime)}`);
    body.push(`DTSTART:${formatDate(event.startTime)}`);
    body.push(`SUMMARY:${event.title}`);
    body.push(`X-MS-OLK-FORCEINSPECTOROPEN:TRUE`);
    // body.push(`DTSTAMP;TZID=${events[0].timezone}:${formatDate(now)}`);
    body.push(`DTSTAMP:${formatDate(now)}`);
    body.push(`UID:${uuidv4()}`);
    body.push(`METHOD:REQUEST`);

    var desc = '';
    if (event.description) {
      desc = addNewlines(event.description)
    }

    event.url && body.push(`URL:${event.url}`);
    event.attendees &&
      event.attendees.forEach(attendee => {
        const regExp = /^([^<]+)\s*<(.+)>/;
        const matches = attendee.match(regExp);
        if (matches) {
          const name = matches[1];
          const email = matches[2];
          body.push(
            [
              "ATTENDEE",
              `CN=${name}`,
              "CUTYPE=INDIVIDUAL",
              "PARTSTAT=NEEDS-ACTION",
              "ROLE=REQ-PARTICIPANT",
              `RSVP=TRUE:mailto:${email}`
            ].join(";")
          );
        }
      });
    event.endTime && body.push(`DTEND:${formatDate(event.endTime)}`);
    desc && body.push(`DESCRIPTION:${desc.trim()}`);
    event.location && body.push(`LOCATION:${event.location}`);
    event.categories && body.push(`CATEGORIES:${event.categories}`);
    event.recurrence && event.recurrenceCount && body.push(`RRULE:FREQ=${event.recurrence};COUNT=${event.recurrenceCount}`);

    body.push('END:VEVENT');
  });

  rawContent && body.push(rawContent);

  const url = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    `PRODID:-//${brand}//Training Hub//EN`,
    body.join("\r\n"),
    "END:VCALENDAR"
  ].join("\r\n");

  if (useDataURL) {
    return encodeURI(`data:text/calendar;charset=utf8,${url}`);
  } else {
    return url;
  }
}

function addNewlines(str: string) {
  var result = '';
  while (str.length > 0) {
    var sub = str.substring(0, 60);
    if (sub.trim()) result += ' ' + sub + '\r\n';
    str = str.substring(60);
  }
  return result.trim();
}

// export function isCrappyIE(): boolean {
//   return !!(
//     typeof window !== "undefined" &&
//     window.navigator.msSaveOrOpenBlob &&
//     window.Blob
//   );
// }