import { View, Text, ScrollView, H1 } from "../../components/ui"
import { Training } from '../../models/TrainingData'
import { useEffect, useState } from "react"
import { AntDesign } from '@expo/vector-icons';
import { ActivityIndicator, useDripsyTheme } from "dripsy"
import { useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { EventHeader } from "../ui/EventHeader"
import { GetHelp } from "../ui/GetHelp"
import { useMember } from "../../hooks/useMember";
import TrainingDetails from "./TrainingDetails"
import CalendarButton from "../ui/CalendarButton";
import { useMediaQuery } from "react-responsive";
import Quizzes from "./Quizzes";
import { useQuery } from "@tanstack/react-query";
import { useMemberApi } from "../../api/useMemberApi";
import AuditorMessage from "../ui/AuditorMessage";
import { useConfig } from "../../config/useConfig";

interface TrainingDetailViewProps {
    id: string
}

export default function TrainingDetailView(props: TrainingDetailViewProps) {
    const { id } = props;
    const { member, showHeader, setHeader } = useMember();
    const { getTrainingData, getTraining } = useMemberApi()
    const { isPending, data, refetch } = useQuery({ queryKey: ['trainingData', member?.email], queryFn: () => getTrainingData(), staleTime: Infinity })
    const [training, setTraining] = useState<Training>();
    const [eventLoading, setEventLoading] = useState(false)
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { theme } = useDripsyTheme();
    const isMobile = useMediaQuery({ query: '(max-width: 1225px)' })
    const { appTheme } = useConfig()

    const trainingData = data

    const notFoundStyle = {
        textAlign: 'center',
        color: 'gray',
        fontStyle: 'italic',
        fontSize: 17,
        fontWeight: 400
    }

    let resellId = 0
    let reggieId = 0

    useEffect(() => {
        async function loadAsync() {
            let tempTraining = null
            let queryForCourse = true

            if (Array.from(id)[0] === 'r') {
                resellId = Number(id.replace(/\D/g, ''))
            } else {
                reggieId = Number(id)
            }

            if (trainingData) {
                const allTraining = [...trainingData.active, ...trainingData.future, ...trainingData.past]
                for (const i in allTraining) {
                    tempTraining = allTraining[i]
                    if ((resellId > 0 && tempTraining.resellId === resellId) || (reggieId > 0 && tempTraining.reggieId === reggieId)) {
                        queryForCourse = false
                        setTraining(tempTraining)
                        break;
                    }
                }

                if (queryForCourse && reggieId) {
                    reloadTraining(reggieId)
                }
            }
        }

        loadAsync()

    }, [id, trainingData]);

    useEffect(() => {
        setHeader(false);
    }, [showHeader])

    async function reloadTraining(reggieId: number) {
        try {
            setEventLoading(true)
            const ntraining = await getTraining(reggieId)
            setTraining(ntraining)
        } catch (err: any) {
            console.warn(err.toString())
        } finally {
            setEventLoading(false)
        }
    }

    function goBack() {
        setHeader(true);
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('TrainingView')
    }

    if (isPending || eventLoading) {
        return (
            <View sx={{ backgroundColor: 'white', height: '100%' }}>
                <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
            </View>
        )
    }

    return (
        <>
            <View sx={{ backgroundColor: 'white', height: '100%' }}>
                {training ? (
                    <>
                        <EventHeader training={training} />
                        <ScrollView sx={{ width: '100%', flex: 1 }}>
                            <View sx={{ marginTop: '$3', marginLeft: '$3', ...theme.layout.viewBack }}>
                                <AntDesign sx={{ cursor: 'pointer' }} name={"left"} size={28} color={theme.colors.$primary} />
                                <Text sx={{ marginTop: 7, cursor: 'pointer', color: appTheme ? appTheme.primaryColor : 'rgb(0, 85, 140)', fontWeight: 600 }} onPress={() => { goBack() }}>Back</Text>
                            </View>
                            <View sx={{ paddingTop: 50, ...theme.layout.view }}>
                                <View sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
                                    <View sx={{ width: isMobile ? '100%' : '50%', flexGrow: 1 }}>
                                        { training.auditor && <AuditorMessage /> }
                                        <TrainingDetails training={training} onEmailValidation={refetch} />
                                        <View sx={{
                                            width: isMobile ? 220 : 'auto',
                                            position: isMobile ? 'absolute' : 'relative',
                                            right: isMobile ? 0 : null,
                                            top: isMobile ? 15 : null,
                                            height: 55,
                                            marginBottom: 25,
                                            zIndex: 100
                                        }}>
                                            <GetHelp />
                                            <CalendarButton training={training} />
                                        </View>
                                    </View>
                                    {!training.auditor &&
                                        <View sx={{ maxWidth: isMobile ? '100%' : '48%', marginLeft: isMobile ? '0%' : '2%' }}>
                                            <Quizzes eventId={training.reggieId} />
                                        </View>
                                    }
                                </View>
                            </View>
                        </ScrollView>
                    </>
                ) : (
                    <View sx={{ position: 'relative', zIndex: 5, height: 150 }}>
                        <H1 sx={{
                            textAlign: 'center',
                            color: 'gray',
                            marginTop: 40
                        }}>404</H1>
                        <H1 sx={notFoundStyle}>Course Not Found</H1>
                    </View>
                )
                }
            </View>
        </>
    )
}
