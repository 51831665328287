import { NativeStackNavigationProp, createNativeStackNavigator } from "@react-navigation/native-stack"
import { useMember } from "../hooks/useMember"
import BottomTabsNavigator from "./BottomTabsNavigator"
import LoginNavigator from "./LoginNavigator"
import TopTabsNavigator from "./TopTabsNavigator"
import { Platform } from "react-native"
import { useNavigation } from "@react-navigation/native"
import UtilityNavigator from "./UtilityNavigator"

const RootStack = createNativeStackNavigator()
export default function RootNavigator() {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { member } = useMember()

    return (
        <RootStack.Navigator initialRouteName={member ? 'Tabs' : 'Onboarding'}>
            {!member ? (
                <RootStack.Screen name="Onboarding" component={LoginNavigator} options={{ headerShown: false }} />
            ) : (
                <RootStack.Screen name="Tabs" component={Platform.OS == 'web' ? TopTabsNavigator : BottomTabsNavigator} options={{ headerShown: false }} />
            )}
            <RootStack.Screen name="Utility" component={UtilityNavigator} options={{ headerShown: false }} />
        </RootStack.Navigator>
    )
}